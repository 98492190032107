<template>
  <div class="main">
    <div class="row px-4 py-4">
      <div class="col-12 pb-4">
        <h1 class="text-center fw-bold">Precios</h1>
        <h5 class="text-center">
          Escoge el plan que más se adapte a las necesidades de su negocio o
          empresa
        </h5>
        <div class="d-flex justify-content-center">
          <button
            class="btn py-2 px-5 me-2"
            type="button"
            v-bind:class="{
              'btn-primary': subType == 'monthly',
              'btn-secondary': subType != 'monthly'
            }"
            @click="subType = 'monthly'"
          >
            Plan Mensual
          </button>
          <button
            class="btn py-2 px-5 ms-2"
            type="button"
            v-bind:class="{
              'btn-primary': subType == 'annual',
              'btn-secondary': subType != 'annual'
            }"
            @click="subType = 'annual'"
          >
            Plan Anual
          </button>
        </div>
      </div>
      <div class="col-xs-12 col-lg-6 col-xl-4 col-xxl-3 pb-4">
        <div class="card h-100">
          <h3 class="text-primary text-center fw-bold my-3">Paramétricas</h3>
          <ul class="list-item">
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Hasta 3 empleados y/o agrícola
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              1 GB de almacenamiento
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Sedes Ilimitadas
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Usuarios Ilimitados
            </li>
            <li class="icon-x">
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#x-circle" />
              </svg>
              Publicidad
            </li>
            <li class="icon-x">
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#x-circle" />
              </svg>
              No aplica para riesgo 5
            </li>
          </ul>
          <h1 v-if="subType == 'monthly'" class="text-center fw-bold">
            <sup class="fs-4">$</sup>{{ subMicro.month
            }}<sub class="fs-4">/mes</sub>
          </h1>
          <del v-if="subType == 'annual'" class="text-center"
            >${{ subMicro.month }}</del
          >
          <h1 v-if="subType == 'annual'" class="text-center fw-bold">
            <sup class="fs-4">$</sup>{{ subMicro.period
            }}<sub class="fs-4">/mes</sub>
          </h1>
          <p v-if="subType == 'annual'" class="text-center fw-bold">
            Se pagará <span>${{ subMicro.year }}</span
            ><sub>/año</sub>
          </p>
          <div class="d-grid mx-4 mb-4">
            <button
              class="btn btn-primary"
              type="button"
              @click="getSubscription('micro')"
            >
              Actualizar
            </button>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-lg-6 col-xl-4 col-xxl-3 pb-4">
        <div class="card h-100">
          <h3 class="text-primary text-center fw-bold my-3">Microempresa</h3>
          <ul class="list-item">
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Hasta 9 empleados
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              5 GB de almacenamiento
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Sedes Ilimitadas
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Usuarios Ilimitados
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              No Publicidad
            </li>
            <li class="icon-x">
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#x-circle" />
              </svg>
              No aplica para riesgo 5
            </li>
          </ul>
          <h1 v-if="subType == 'monthly'" class="text-center fw-bold">
            <sup class="fs-4">$</sup>{{ subPyme.month
            }}<sub class="fs-4">/mes</sub>
          </h1>
          <del v-if="subType == 'annual'" class="text-center"
            >${{ subPyme.month }}</del
          >
          <h1 v-if="subType == 'annual'" class="text-center fw-bold">
            <sup class="fs-4">$</sup>{{ subPyme.period
            }}<sub class="fs-4">/mes</sub>
          </h1>
          <p v-if="subType == 'annual'" class="text-center fw-bold">
            Se pagará <span>${{ subPyme.year }}</span
            ><sub>/año</sub>
          </p>
          <div class="d-grid mx-4 mb-4">
            <button
              class="btn btn-primary"
              type="button"
              @click="getSubscription('pyme')"
            >
              Actualizar
            </button>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-lg-6 col-xl-4 col-xxl-3 pb-4">
        <div class="card h-100">
          <h3 class="text-primary text-center fw-bold my-3">Pyme</h3>
          <ul class="list-item">
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Hasta 49 empleados
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              15 GB de almacenamiento
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Sedes Ilimitadas
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Usuarios Ilimitados
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              No Publicidad
            </li>
            <li class="icon-x">
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#x-circle" />
              </svg>
              No aplica para riesgo 5
            </li>
          </ul>
          <h1 v-if="subType == 'monthly'" class="text-center fw-bold">
            <sup class="fs-4">$</sup>{{ subMedium.month
            }}<sub class="fs-4">/mes</sub>
          </h1>
          <del v-if="subType == 'annual'" class="text-center"
            >${{ subMedium.month }}</del
          >
          <h1 v-if="subType == 'annual'" class="text-center fw-bold">
            <sup class="fs-4">$</sup>{{ subMedium.period
            }}<sub class="fs-4">/mes</sub>
          </h1>
          <p v-if="subType == 'annual'" class="text-center fw-bold">
            Se pagará <span>${{ subMedium.year }}</span
            ><sub>/año</sub>
          </p>
          <div class="d-grid mx-4 mb-4">
            <button
              class="btn btn-primary"
              type="button"
              @click="getSubscription('medium')"
            >
              Actualizar
            </button>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-lg-6 col-xl-4 col-xxl-3 pb-4">
        <div class="card h-100">
          <h3 class="text-primary text-center fw-bold my-3">Plus</h3>
          <ul class="list-item">
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Hasta 199 empleados
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              100 GB de almacenamiento
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Sedes Ilimitadas
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Usuarios Ilimitados
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              No Publicidad
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Aplica para riesgo 5
            </li>
          </ul>
          <h1 v-if="subType == 'monthly'" class="text-center fw-bold">
            <sup class="fs-4">$</sup>{{ subBig.month
            }}<sub class="fs-4">/mes</sub>
          </h1>
          <del v-if="subType == 'annual'" class="text-center"
            >${{ subBig.month }}</del
          >
          <h1 v-if="subType == 'annual'" class="text-center fw-bold">
            <sup class="fs-4">$</sup>{{ subBig.period
            }}<sub class="fs-4">/mes</sub>
          </h1>
          <p v-if="subType == 'annual'" class="text-center fw-bold">
            Se pagará <span>${{ subBig.year }}</span
            ><sub>/año</sub>
          </p>
          <div class="d-grid mx-4 mb-4">
            <button
              class="btn btn-primary"
              type="button"
              @click="getSubscription('big')"
            >
              Actualizar
            </button>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-lg-6 col-xl-4 col-xxl-3 pb-4">
        <div class="card h-100">
          <h3 class="text-primary text-center fw-bold my-3">Corporativo</h3>
          <ul class="list-item">
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Empleados según acuerdo
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Almacenamiento personalizado
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Sedes Ilimitadas
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Usuarios Ilimitados
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              No Publicidad
            </li>
            <li>
              <svg class="bi me-2" fill="currentColor">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check-circle" />
              </svg>
              Aplica para riesgo 5
            </li>
          </ul>
          <h2 class="text-center fw-bold mx-3">Según Acuerdo</h2>
          <div class="d-grid mx-4 mb-4 mt-2">
            <button class="btn btn-primary" type="button">Contacto</button>
          </div>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<style scoped lang="css">
.list-item {
  list-style-type: none;
}
.list-item svg {
  color: var(--bs-success);
  height: 16px;
  width: 16px;
}
.icon-x svg {
  color: var(--bs-danger);
}
</style>

<script>
import Legal from "@/components/layout/Legal";
import Storage from "@/modules/storage";

const store = new Storage();

const formatter = new Intl.NumberFormat("es-CO", {
  style: "decimal",
  currency: "COP"
});

export default {
  data() {
    return {
      subMicro: {},
      subPyme: {},
      subMedium: {},
      subBig: {},
      subType: "monthly"
    };
  },
  mounted() {
    store.getData("plan").then(data => {
      data.forEach(sub => {
        if (sub.name === "Paramétricas Mensual") {
          this.subMicro.monthID = sub._id;
          this.subMicro.month = formatter.format(sub.value);
        } else if (sub.name === "Paramétricas Anual") {
          this.subMicro.yearID = sub._id;
          this.subMicro.year = formatter.format(sub.value);
          this.subMicro.period = formatter.format(sub.value / 12);
        } else if (sub.name === "Microempresa Mensual") {
          this.subPyme.monthID = sub._id;
          this.subPyme.month = formatter.format(sub.value);
        } else if (sub.name === "Microempresa Anual") {
          this.subPyme.yearID = sub._id;
          this.subPyme.year = formatter.format(sub.value);
          this.subPyme.period = formatter.format(sub.value / 12);
        } else if (sub.name === "Pyme Mensual") {
          this.subMedium.monthID = sub._id;
          this.subMedium.month = formatter.format(sub.value);
        } else if (sub.name === "Pyme Anual") {
          this.subMedium.yearID = sub._id;
          this.subMedium.year = formatter.format(sub.value);
          this.subMedium.period = formatter.format(sub.value / 12);
        } else if (sub.name === "Plus Mensual") {
          this.subBig.monthID = sub._id;
          this.subBig.month = formatter.format(sub.value);
        } else if (sub.name === "Plus Anual") {
          this.subBig.yearID = sub._id;
          this.subBig.year = formatter.format(sub.value);
          this.subBig.period = formatter.format(sub.value / 12);
        }
      });
    });
  },
  components: {
    Legal
  },
  methods: {
    getSubscription(subscription) {
      store.getData("company").then(company => {
        const data = {};
        if (subscription === "micro" && this.subType === "monthly") {
          data.planId = this.subMicro.monthID;
        } else if (subscription === "micro" && this.subType === "annual") {
          data.planId = this.subMicro.yearID;
        } else if (subscription === "pyme" && this.subType === "monthly") {
          data.planId = this.subPyme.monthID;
        } else if (subscription === "pyme" && this.subType === "annual") {
          data.planId = this.subPyme.yearID;
        } else if (subscription === "medium" && this.subType === "monthly") {
          data.planId = this.subMedium.monthID;
        } else if (subscription === "medium" && this.subType === "annual") {
          data.planId = this.subMedium.yearID;
        } else if (subscription === "big" && this.subType === "monthly") {
          data.planId = this.subBig.monthID;
        } else if (subscription === "big" && this.subType === "annual") {
          data.planId = this.subBig.yearID;
        }
        window.location.href =
          store.baseURL +
          "/subscription/subscription/gateway/" +
          data.planId +
          "/" +
          company[0]._id;
      });
    }
  }
};
</script>
